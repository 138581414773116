$yellow: #e2ad49;
$white: #ffffff;
$brown: #333333;
$black: #000000;

.mb {
	margin-bottom: 50px;
}

.white {
	background-color: $white;
}

a.compraSuAmazon {
	border: solid 1px $brown;
	padding: 10px;
	color: #333333;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
}
a:hover.compraSuAmazon {
	border: solid 1px $yellow;
	color: $yellow;
	text-decoration: none;
}
.noPadding {
	padding-left: 0px;
	padding-right: 0px;
}
.noPiace {
	display: none;
}
.mt {
	margin-top: 75px !important;
}
.positionFixed {
	position: fixed;
}