@media (min-width: 991px) and (max-width: 1200px) {
	.overlay .menu {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.two_blocks {
		margin: 80px 0;
	}
	.products-slider {}
	.single_widget_tab {
		padding: 0;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.ipad-veriticale {
		a {
			img {
				margin: 0 auto;
			}
			i {
				margin-top: 10px;
			    position: absolute;
			    top: 0px;
			    bottom: 0px;
			    left: 0;
			    right: 0;
			}
		}
	}
	.resources {
		h4.apartaments_box_titles {
			margin-top: 0px !important;
		}
		p {
			margin-bottom: 20px;
		}
	}
	.titolo_news {
		margin-bottom: 10px;
	}
	.fancy-row {
		margin-top: 50px;
		margin-bottom: 50px;
		// .fancy-text-container {
		// 	margin-top: 0px;
		// }
		// .fancy-text {
		// 	margin-left: 0px;
		// }
		div {
			// padding-left: 15px;
			// padding-right: 15px;
			div {
				// padding-left: 0px;
				// padding-right: 0px;
				a {
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	.mobile_title {
		background-color: $white;
		position: absolute;
		top: 50%;
		vertical-align: middle;
		left: 0;
		right: 0;
		h1 {
			//font-size: 20px;
			color: #58585a;
			font-weight: 700;
			font-family: 'Montserrat', sans-serif;
			margin-top: 30px;
			letter-spacing: 3px;
			//padding: 10px 0;
		}
	}
	i.icon-cancel-5 {
		padding: 10px;
	}
	#nav-close {
		font-size: 20px;
	}
	.main-mobile-img {
		padding-right: 0px;
		padding-left: 0px;
	}
	.white-text {
		padding: 30px 0;
		h3 {
			font-size: 20px;
		}
	}
	.two_blocks {
		margin: 80px 0 30px 0;
		a {
			p {
				font-size: 15px;
			}
		}
	}
	.footerMenu {
		.menu-secondary-header-menu-container, .menu-secondary-header-menu-it-container {
			ul {
				//padding-left: 10px;
				li {
					float: none;
					list-style-type: none;
					//padding: 12px 0;
					a {
					    color: #6F7D8C !important;
					    text-decoration: none;
					    padding: 0px;
					    //font-size: 18px;
					}
				}
			}
		}
	}
	nav {
		.menu-secondary-header-menu-container, .menu-secondary-header-menu-it-container {
			ul {
				padding-left: 10px;
				li {
					float: none;
					list-style-type: none;
					padding: 12px 0;
					a {
					    color: #6F7D8C !important;
					    text-decoration: none;
					    padding: 0px;
					    font-size: 18px;
					}
				}
			}
		}
	}
	ul#language {
		padding-left: 10px;
		li {
			list-style-type: none;
		}
	}
	div#lang_sel_list {
		padding: 0px;
	}
	.navbar {
		// height: 80px;
	 //    -webkit-transition: all 0.3s ease;
	 //    -moz-transition: all 0.3s ease;
	 //    -o-transition: all 0.3s ease;
	 //    transition: all 0.3s ease;
	 	margin-bottom: 0px;
	 	min-height: 80px;
	 	border: none !important;
	    a {
	    	&.logo-mobile {
			    position: absolute;
			    width: 100%;
			    left: 0;
			    top: 0;
			    text-align: center;
			    margin: 0 auto;
				//margin-top: 15px;
			    height:100%;
	    	}
	    	img {
	    		margin-top: 15px;
	    	}
	    }
	}
}
@media (min-width: 992px) {
	.fancy-text-container {
		margin-top: 0px;
	}
	.first-col, .third-col {
		h3 {
			font-size: 15px;
		}
	}
}
@media (min-width: 320px) and (max-width: 991px) {
	.bookingAnchor {
		margin-bottom: 30px;
		a:hover {
			text-decoration: none;
		}
		a {
			padding: 10px;
			border: solid 1px $brown;
			span {
				color: $brown;
			}
		}
	}
	.displayBlock {
		display: block !important;
	}
	#blackHoverBody {
	    display: none;
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 1000;
	    height: 100%;
	    width: 100%;
	    background-color: rgba($brown, 0.6);
	    overflow: hidden;
	}
	nav {
		.menu-secondary-header-menu-container ul li a {
			color: $white !important;
			padding-left: 10px !important;
		}
		.main-menu li a {
			color: $white !important;
			padding-left: 20px !important;
		}
	}
	.mt-m {
		margin-top: 75px !important;
	}
	h1.entry-title {
		font-size: 45px;
	}
	.new_title h1 {
		font-size: 40px;
	}
	.mobile-container {
		margin-top: 20px;
	}
	.default_P {
		margin-top: 0px;
	}
	.tac {
		margin-bottom: 20px;
	}
	.footer {
		.menu-secondary-header-menu-container, .menu-secondary-header-menu-it-container {
			ul {
				//padding-left: 10px;
				li {
					float: none;
					list-style-type: none;
					//padding: 12px 0;
					a {
					    color: #808080 !important;
					    text-decoration: none;
					    padding: 0px;
					    //font-size: 18px;
					}
				}
			}
		}
	}
	.footerMenu {
		.menu-secondary-header-menu-container, .menu-secondary-header-menu-it-container{
			ul {
				//padding-left: 10px;
				li {
					float: none;
					list-style-type: none;
					//padding: 12px 0;
					a {
					    color: #6F7D8C !important;
					    text-decoration: none;
					    padding: 0px;
					    //font-size: 18px;
					}
				}
			}
		}
	}
	nav {
		.menu-secondary-header-menu-container, .menu-secondary-header-menu-it-container {
			ul {
				padding-left: 10px;
				li {
					float: none;
					list-style-type: none;
					padding: 12px 0;
					a {
					    color: $white !important;
					    text-decoration: none;
					    padding: 0px;
					    font-size: 18px;
					}
				}
			}
		}
		.menu-secondary-header-menu-it-container {
			ul {
				padding-left: 20px;
			}
		}
	}
	ul#language {
		padding-left: 10px;
		li {
			list-style-type: none;
		}
	}
	div#lang_sel_list {
		padding: 0px;
	}
	.mobile_title {
		background-color: $white;
		position: absolute;
		//top: 50%;
		vertical-align: middle;
		left: 0;
		right: 0;
		h1 {
			font-size: 25px;
			color: #58585a;
			font-weight: 700;
			font-family: 'Montserrat', sans-serif;
			margin-top: 30px;
			letter-spacing: 3px;
			//padding: 10px 0;
		}
	}
	.wrapper {
		-webkit-transition: 0.15s ease;
		transition: 0.15s ease;
	}
	.wrapper.open .arrow {
		-webkit-transform: rotate(45deg) translate(5px, 5px);
		transform: rotate(45deg) translate(5px, 5px);
	}
	.wrapper.open .arrow:before {
		-webkit-transform: translate(10px, 0);
		transform: translate(10px, 0);
	}
	.wrapper.open .arrow:after {
		-webkit-transform: rotate(90deg) translate(10px, 0);
		transform: rotate(90deg) translate(10px, 0);
	}
	.wrapper.open ul {
		height: auto;
	}
 //  	.navbar {
	// 	// height: 80px;
	//  //    -webkit-transition: all 0.3s ease;
	//  //    -moz-transition: all 0.3s ease;
	//  //    -o-transition: all 0.3s ease;
	//  //    transition: all 0.3s ease;
	//  	margin-bottom: 0px;
	//  	min-height: 0px;
	//     a {
	//     	&.logo-mobile {
	// 		    position: absolute;
	// 		    width: 100%;
	// 		    left: 0;
	// 		    top: 0;
	// 		    text-align: center;
	// 		    margin: auto;
	// 			margin-top: 15px;
	// 		    height:100%;
	//     	}
	//     }
	// }
	.main-mobile-img {
		padding-right: 0px;
		padding-left: 0px;
		background-color: #000;
		min-height: 10px;
		position: relative;
		z-index: 100;

		.img_full {
		width: 100%;
		//opacity: 0.5;
	}
		.logo_on_img {
			position: absolute;
    height: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 150px;
    overflow: auto;
    margin: auto;
		z-index: 200;
		}
	}


	.center {
		text-align: center;
	}
	#carousel-apartaments {
		.item {
			a {
				display: block;
				margin-top: 50px;
			}
		}
	}
	#carousel-rooms {
		.item {
			a {
				display: block;
				margin-top: 50px;
			}
		}
	}
	#carousel-cities {
		.item {
			a {
				display: block;
				margin-top: 50px;
			}
		}
	}
	.overlay {
		.menu {
			// width: 100%;
			.container {
				max-width: 100%;
			}
		}
	}
	.slider_mobile {
		margin-bottom: 50px;
	}
	.video_mobile {}
	.mobile-container {
		//margin-top: 85px;
	}
	.navmenu-default, .navbar-default .navbar-offcanvas {
		background-color: #282529;
		border: 0;
	}
	#btn-close {
		font-size: 25px;
		top: 33px;
		-webkit-appearance: none;
		background-color: #282529;
		border: 0;
		margin-bottom: 40px;
		margin-left: 5px;
	}
	// .two_blocks .bl_1,
	// .two_blocks .bl_2 {
	//     padding: 0;
	// }
	/*PRE RESOURCE*/
	.tabs_content_pre_risorsa p {
		padding: 0 10px;
	}
	.resources {
		margin-top: 20px;
		h3 {
			margin: 30px 0;
		}
	}
	.mobile_description {
		margin-bottom: 50px;
	}
	.mobile_description .from {
		color: #58585a;
		margin: 0;
	}
	.mobile_description .price {
		color: #e2ad49;
		font-size: 20px;
		font-family: 'Montserrat', sans-serif;
		font-weight: 400;
		margin-bottom: 30px;
	}
	ul.acf_single_resource_mobile {
		list-style: none;
		padding: 0;
		text-decoration: none;
	}
	ul.acf_single_resource_mobile li {
		display: inline-block;
		margin: 0 5px;
	}
	ul.acf_single_resource_mobile li i {
		color: #58585a;
		font-size: 30px;
	}
	ul.acf_single_resource_mobile li span.acf_single_resource_text {
		color: #58585a;
	}
	.discover_button {
		text-decoration: none;
		color: #58585a;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		padding: 5px 10px;
		background: #e2ad49;
		border-radius: 5px;
	}
	footer {
		padding-top: 30px;
	}
	.first-col {
		margin-bottom: 50px;
	}
	.third-col {
		margin-bottom: 30px;
	}
	/*.carousel-control.left,
    .carousel-control.right {
        background-image: none;
    }
    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next,
    .carousel-control .icon-prev {
        font-size: 40px;
    }*/
	/*.carousel-indicators {
        bottom: -20px;
    }*/
	.slider_mobile .carousel-indicators li {
		border: 1px solid #fff;
	}
	.slider_mobile .carousel-indicators .active {
		background-color: #fff;
	}
	.accordion-resources a {
		text-decoration: none;
		margin-bottom: 30px;
	}
	.panel-body p {
		color: #8e9091;
	}
	.accordion-resources .panel-default {
		border: 1px solid #e2ad49;
	}
	.accordion-resources .panel-default>.panel-heading {
		text-align: center;
		background-color: #e2ad49;
		color: #fff;
		border-color: #e2ad49;
	}
	.accordion-resources .panel-default>.panel-heading:hover {
		background-color: #fff;
		color: #e2ad49;
		text-decoration: none;
	}
	.accordion-resources .panel-default>.panel-heading>.panel-title>a:hover {
		text-decoration: none;
	}
	.accordion_separator {
		height: 5px;
		width: 100%;
		display: block;
	}
	.ctp_post_content {
		column-count: 1;
	}
	.single_attribute.check {
		text-align: center;
	}
	// .single_attribute {
	//     text-align: center;
	// }
	.post_container {}
	.fancy-row a:first-child img {
		margin-bottom: 15px;
	}
	.fancy-text p {
		padding: 0;
	}
	.col-centered {
		margin-top: 10px;
		// span {
		//     &.attribute_text {
		//         text-align: center;
		//     }
		// }
	}
	.category_list_title {
		h3 {
			margin: 30px 0;
		}
	}
	.single_calendar {
		margin-bottom: 20px;
		padding-left: 0;
		.single_dates {
			color: #a3a5a6;
			font-size: 12px;
			margin-bottom: 5px;
		}
		.single_price_week {
			color: #707173;
			font-family: 'Montserrat', sans-serif;
			font-weight: 700;
			font-size: 15px;
		}
		.single_price_night {
			color: #a3a5a6;
			font-size: 11px;
			font-family: 'Montserrat', sans-serif;
			font-weight: 300;
		}
	}
	.products-slider {
		height: 100%;
	}
  .noscroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

}

@media (min-width: 480px) and (max-width: 767px) {
	.overlay .menu ul li {
		float: none;
		margin-bottom: 50px;
		width: 100%;
		border-bottom: 1px solid #575757;
	}
	.overlay .menu ul li:last-child {
		border: 0;
	}
	.overlay .menu ul ul {
		margin-top: 20px;
	}
	.menu-btn {
		right: 25px;
	}
	.dropdown.menu-mobile .btn-default {
		background-color: transparent;
		border: none;
	}
}

@media (min-width: 320px) and (max-width: 767px) {
	.blue-text {
		h3 {
			font-size: 45px;
		}
	}
	.navbar-fixed-top {
		border-width: 0px;
	}
	a#nav-expander {
	    margin-top: 10px;
	}
	.two_blocks .bl_1, .two_blocks .bl_2 {
		padding: 0;
	}
	.navbar {
		// height: 80px;
	 //    -webkit-transition: all 0.3s ease;
	 //    -moz-transition: all 0.3s ease;
	 //    -o-transition: all 0.3s ease;
	 //    transition: all 0.3s ease;
	 	margin-bottom: 0px;
	 	min-height: 0px;
	    a {
	    	&.logo-mobile {
			    position: absolute;
			    width: 100%;
			    left: 0;
			    top: 0;
			    text-align: center;
			    margin: auto;
				margin-top: 15px;
			    height:100%;
	    	}
	    }
	}
}
