/*
Theme Name: Castello di Lispida
*/
/* GENERAL RULES */
.wp-core-ui .button-primary {
    background-color: $yellow !important;
}
.langColorLI {
    background-color: #e3ad49;
    color: #ffffff;
    border: 2px solid #e3ad49;
}
.langColorA {
    background-color: #ffffff;
    color: #e3ad49;
    //border: 2px solid #e3ad49;
}
.langColorLIpost {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #ffffff;
}
.langColorApost {
  background-color: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}
html,body {
    /*font-family: 'Montserrat', sans-serif;*/
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #8e9091;
    background-color: #fff;
    height:100%;
}
.ttu {
    text-transform: uppercase;
}
.tac {
    text-align: center;
}
.tal{
    text-align: left;
}
.hidden {
    display: none;
}
.display {
  display: block;
}
.bg-white {
  background-color: white;
  z-index: 1000;
}

.highlight {
  &:target {
   -webkit-animation: fadeIt 1s ease-in-out;
   -moz-animation: fadeIt 1s ease-in-out;
   -o-animation: fadeIt 1s ease-in-out;
   animation: fadeIt 1s ease-in-out;
    @-webkit-keyframes fadeIt {
      0%   { background-color: #FFFFFF; }
      50%  { background-color: $yellow; }
      100% { background-color: #FFFFFF; }
    }
    @-moz-keyframes fadeIt {
      0%   { background-color: #FFFFFF; }
      50%  { background-color: $yellow; }
      100% { background-color: #FFFFFF; }
    }
    @-o-keyframes fadeIt {
      0%   { background-color: #FFFFFF; }
      50%  { background-color: $yellow; }
      100% { background-color: #FFFFFF; }
    }
    @keyframes fadeIt {
      0%   { background-color: #FFFFFF; }
      50%  { background-color: $yellow; }
      100% { background-color: #FFFFFF; }
    }
  }
}

/* HEADER RULES */
.navbar-default {
    background: transparent;
    border: none !important;
    // height: 150px;
    padding: 20px 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    .navbar-header {
      a {
        img {
          height: auto;
          margin-left: 100px;
          margin-right: 100px;
        }
      }
    }
}
/*NAVBAR RIGHT*/

.glyphicon-shopping-cart {
    color: #fff;
    font-size: 20px;
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.glyphicon-shopping-cart:hover {
    color: $yellow;
}
span.products_item_count {
    color: #fff;
}


// NEW WPML
.wpml-ls-statics-shortcode_actions {
  background-color: transparent;
  border: none;
  ul {
    li.wpml-ls-current-language {
      background-color: $yellow;
      //border: 2px solid $yellow !important;
    }
    li {
      &.wpml-ls-item {
        border: 2px solid #fff;
        border-radius: 50% !important;
        margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        a{
          width: 35px;
          height: 35px;
          line-height: 35px;
          background: transparent;
          padding: 0 !important;
          border-radius: 50%;
          text-align: center;
          display: inline-block;
          span {
            font-size: 12px;
          }
        }
      }
      &:hover {
          border: 2px solid #e3ad49;
          background-color: #e3ad49;
      }
    }
  }
}
/* OPEN / CLOSE BTNS */

.menu-btn {
    z-index: 999;
    display: inline;
    font-size: 32px;
    position: absolute;
   a {
      display: inline-block;
      text-decoration: none;
    }
  }
.btn-open:after {
    color: #fff;
    content: "\e80d";
    font-family: "fontello";
    -webkit-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition-property: all .2s linear 0s;
}
.btn-open-2:after {
    color: $yellow;
    content: "\e80d";
    font-family: "fontello";
    -webkit-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition-property: all .2s linear 0s;
}
.btn-open:hover:after {
    color: #dcac46;
}
.btn-close:after {
    color: #59595b;
    content: "\e805";
    z-index: 20;
    font-family: "fontello";
    -webkit-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition-property: all .2s linear 0s;
}
.btn-close:hover:after {
    color: #dcac46;
}
/* OVERLAY */
.block_scroll{
    overflow: hidden;
}

.overlay {
    position: fixed;
    top: 0;
    z-index: 900;
    display: none;
    overflow: auto;
    width: 100%;
    height: 100%;
    background: #333;
}
.overlay .menu {
    margin: 10% auto;
    width: 80%;
}
.overlay .menu ul {
    margin: 0;
    padding: 0;
    width: 100%;
}
.overlay .menu ul li {
    list-style: none;
    color: #fff;
}
.overlay .menu ul li a {
    color: #7e7e80;
    font-size: 15px;
    text-decoration: none;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.overlay .menu ul li a.single-menu-item-title {
    color: #fff;
    text-decoration: none;
    font-size: 17px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.overlay .menu ul li a.single-menu-item-title:hover {
    color: #dcac46;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.overlay .menu ul li a:hover {
    color: #dcac46;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.overlay .menu .logo-menu {
    text-align: center;
    margin-bottom: 50px;
}

.sub-header-menu {
    margin-top: 100px;

  .sub-header-menu li a {
    text-decoration: none;
    color: $yellow;
    -webkit-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition-property: all .2s linear 0s;
  }
}
.sub-menu-item a:hover {
  color: #fff;
}

/* HOMEPAGE CONTENT*/

/* Fullscreen setup */

/* background setup */
.main_image {
    overflow: scroll;
    height: 100%;
}
.main_image.fullscreen.background {
    // background-image: url(../../images/Lispida_Light.jpg);
    background-repeat:no-repeat;
    /* custom background-position */
    background-position:50% 50%;
    /* ie8- graceful degradation */
    background-position:50% 50%\9 !important;
}
// .background {
//     background-repeat:no-repeat;
//     /* custom background-position */
//     background-position:50% 50%;
//     /* ie8- graceful degradation */
//     background-position:50% 50%\9 !important;
// }
.fullscreen,
.content-a {
    width:100%;
    height:100%;
    overflow:hidden;
}
.fullscreen.overflow,
.fullscreen.overflow .content-a {
    height:auto;
    min-height:100%;
}

/* content centering styles */
.content-a {
	display:table;
}
.content-b {
	display:table-cell;
    position:relative;
	vertical-align:middle;
	text-align:center;
}

#rooms_switch {
  margin-top: 50px;
    ul.rooms_list{
      list-style: none;
        li {
        display: inline;
        // background-color: #fff;
        padding: 20px 0 20px 40px;
        // border-right: 1px solid #59595b;
        height: 10px;
        background: #fff url(../../images/border.png) center right no-repeat;
          a {
          text-decoration: none;
          /*border-right: 1px solid #59595b;
          height: 10px;*/
          padding-right: 40px;
          color: #58585a;
          font-family: 'Montserrat', sans-serif;
          -webkit-transition: all .2s linear 0s;
          -moz-transition: all .2s linear 0s;
          -o-transition: all .2s linear 0s;
          transition-property: all .2s linear 0s;
            &:hover {
            color: #e3ad49;
            }
          }
        &:last-child{
          background-image: none;
        }
      }
    }
}

.second_image_home {
    text-align: center;
    -webkit-transition: all .3s linear 0s;
    -moz-transition: all .3s linear 0s;
    -o-transition: all .3s linear 0s;
    transition-property: all .3s linear 0s;
}
.second_image_home:hover {
    opacity: 0.9;
}
.second_image_home a:hover {
    text-decoration: none;
}
p.home_small_title {
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-size: 30px;
    letter-spacing: 5px;
    margin: 10%;
}
.three_resources {
    margin: 100px 0 50px 0;
}
h3.single-resource-title {
    color: #58585a;
    font-size: 20px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}
.single-resources-list {
    text-align: center;
}
.single-resources-list ul.list {
    padding-left: 0;
    margin-top: 30px;
}
.single-resources-list ul.list li {
    list-style: none;
    margin: 5px 0;
}
.single-resources-list ul.list li a {
    text-decoration: none;
    color: #8e9091;
    -webkit-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition-property: all .2s linear 0s;
}
.single-resources-list ul.list li a:hover {
    color: $yellow;
}
.two_blocks {
    text-align: center;
}
.two_blocks a img {
    -webkit-filter: blur(0);
	filter: blur(0);
    -webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    z-index: 5;
}
.two_blocks a {
    text-decoration: none;
}
.two_blocks a p {
    color: #58585a;
    font-size: 20px;
    text-decoration: none;
    position: absolute;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    top: 50%;
    left: 50%;
    margin-left: -30%;
    margin-right: -30%;
    right: 50%;
    background-color: #fff;
    padding: 10px 10px;
    z-index: 7;
    -webkit-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition-property: all .2s linear 0s;
}
.two_blocks .bl_1:hover a p {
    background-color: $yellow;
    color: #fff;
}
.two_blocks .bl_1:hover a img{
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  z-index: 5;
}
.two_blocks .bl_2:hover a p {
    background-color: $yellow;
    color: #fff;
}
.two_blocks .bl_2:hover a img{
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  z-index: 5;
}
/*Product slider HP*/
.products-slider {
    background-color: #e8e8e8;
    //height: 600px;
}
#productCarousel .carousel-indicators li {
    border: 1px solid #a3a5a6;
}
#productCarousel .carousel-indicators .active {
    background-color: #808080;
}
.carousel-indicators {
  // bottom: 30px;
}
#productCarousel {
  .carousel-inner {
    margin-bottom: 40px;
  }
}
#productCarousel {
    padding: 50px 0;
}
.prod_attributes_container {
    position: relative;
    height: 360px;
}
.prod_attributes {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    text-align: right;
    width: 100%;
}
.prod_attributes ul {
    padding: 0;
    margin-right: 50px;
}
.prod_attributes ul li:nth-child(2) {
    margin-right: 30px;
}
.prod_attributes ul li {
    list-style: none;
    margin: 60px 0;
}
.prod_attributes_single h5 {
    margin-bottom: 0;
    font-size: 13px;
}
.prod_attributes_single p {
    color: #707173;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 2px;
}
.product_description h1 {
    color: #58585a;
    text-transform: uppercase;
    font-size: 25px;
    letter-spacing: 3px;
    margin: 0;
}
.product_description p {
    color: #707173;
    line-height: 25px;
    font-size: 13px;
    letter-spacing: 1px;
}
.product_description {
    margin-top: 10px;
}
span.prod_price {
    border: 1px solid #b8b9ba;
    padding: 5px 30px;
    border-radius: 25px;
    color: #727576;
}
a.go_to_product_details{
    color: #58585a;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 10px;
    background-color: transparent;;
    padding: 8px 50px;
    border: 1px solid #b8b9ba;
    border-radius: 5px;
    -moz-transition: ease-out 0.3s 0.1s;
    -o-transition: ease-out 0.3s 0.1s;
    -webkit-transition: ease-out 0.3s;
    -webkit-transition-delay: 0.1s;
    transition: ease-out 0.3s 0.1s;
}
button.add_to_cart_btn {
    -webkit-appearance:none;
    border:none;
    background-color: transparent;
}
button.add_to_cart_btn a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 10px;
    background-color: #3e3d40;
    padding: 8px 50px;
    border: 1px solid #3e3d40;
    border-radius: 5px;
    -moz-transition: ease-out 0.3s 0.1s;
    -o-transition: ease-out 0.3s 0.1s;
    -webkit-transition: ease-out 0.3s;
    -webkit-transition-delay: 0.1s;
    transition: ease-out 0.3s 0.1s;
}
a.go_to_product_details:hover,
button.add_to_cart_btn a:hover {
    color: #3e3d40;
    background-color: $yellow;
    padding: 8px 50px;
    border: 1px solid $yellow;
}

.blue-overlay {
    background: rgba(59, 191, 233, 0.6);
    padding: 30px 0;
}
/*.blue-text {
    background-repeat: no-repeat;
}*/
.blue-text h3 {
    /*margin-top: 110px;*/
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.blue-text p {
    margin-top: 30px;
    color: #fff;
    letter-spacing: 3px;
    line-height: 30px;
}
.white-text {
    padding: 60px 0;
}
.white-text h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #58585a;
    font-size: 25px;
}
.white-text p {
    color: #8e9091;
    font-size: 15px;
    margin-bottom: 30px;
    letter-spacing: 1px;
    line-height: 30px;
}
/*.white-text a {
    color: #808080;
    font-size: 15px;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 1px solid $yellow;
}*/
.cta {
  a {
    padding: 10px;
    border: solid 1px $black;
    span {
      color: #808080;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;    
    }
  }
  a:hover {
    text-decoration: none;
    //border: solid 1px $yellow;
    background-color: $yellow;
    span {
      color: $black;
    }
  }
}
.link-effect-10 a {
  color: #808080;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  margin: 0 20px;
  padding: 10px;
  text-decoration: none;
  position: relative;
  -moz-transition: ease-out 0.3s 0.1s;
  -o-transition: ease-out 0.3s 0.1s;
  -webkit-transition: ease-out 0.3s;
  -webkit-transition-delay: 0.1s;
  transition: ease-out 0.3s 0.1s;
}
.link-effect-10 a span::before {
  height: 100%;
  width: 1px;
  background: $yellow;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.link-effect-10 a span::after {
  height: 100%;
  width: 1px;
  background: $yellow;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.link-effect-10 a::before {
  height: 1px;
  width: 100%;
  background: $yellow;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.link-effect-10 a::after {
  height: 1px;
  width: 100%;
  background: $yellow;
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
/*.link-effect-10 a:hover {
  color: $yellow;
}*/
.link-effect-10 a:hover::before, .link-effect-10 a:hover::after {
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.link-effect-10 a:hover span::before, .link-effect-10 a:hover span::after {
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

/* SLIDESHOW */
.slider {
    margin-bottom: 150px;
}
/*FOOTER*/
footer {
    background-color: #282529;
    padding: 100px 0;
    .logo-footer {
    }
}
.third-col h3,
.first-col h3 {
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    margin-top: 0;
}
.first-col ul {
    list-style: none;
    padding-left: 0;
}
.first-col ul li a {
    font-size: 15px;
    text-decoration: none;
    color: #808080;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.first-col ul li a:hover {
    color: $yellow;
}
.first-col a span {
    font-size: 35px;
    color: #707173;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.first-col a span::before {
    margin: 0;
}
.first-col a span:hover {
    color: $yellow;
}
.third-col p {
    color: #707173;
    font-size: 12px;
}
.third-col a,
.third-col p a {
    color: #fff;
    text-decoration: none;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.third-col a:hover,
.third-col p a:hover {
    color: $yellow;
}

/*SLIDER*/
.btn-wrap {
    margin-top: 10px;
}
a.btn-slider {
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  border: 1px solid $yellow;
  color: #ffffff;
  height: 10px;
  font-size: 13px;
  background: $yellow;
  padding: 5px 30px;
  text-decoration: none;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

a.btn-slider:hover {
  background: transparent;
  text-decoration: none;
  color: $yellow;
  border: 1px solid $yellow;
}
/*.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
}*/
// #testvideo {
//   height: 100px;
// }
// .videoWrapper {
//     position: relative;
//     padding-bottom: 56.25%;
//     height: 0;
//     overflow: hidden;
//     max-width: 100%;
// }
.videoWrapper iframe,
.videoWrapper object,
.videoWrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.switch {
	display: none !important;
}
/*PRE RISORSA*/

.content-b .page-title {
    width: 500px;
    position: absolute;
    vertical-align: middle;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50%;
    margin: auto;
}
h1.pre-resource-mobile-title,
.content-b .page-title h1.pre-resource{
    background-color: #fff;
    padding: 15px 0;
    font-size: 25px;
    color: #3e3d40;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    letter-spacing: 3px;
    text-align: center;
}
.nav-tabs > li, .nav-pills > li {
    float:none;
    display:inline-block;
}
.nav-tabs {
    text-align:center;
    border: 0;
}
.nav-tabs > li > a {
    padding: 5px 50px !important;
    border: 1px solid $yellow;
    text-decoration: none;
    color: #58585a;
    text-transform: uppercase;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin: -2px !important;
}
.nav-tabs > li:first-child > a{
    border-radius: 4px 0 0 4px !important;
    border-right: 0;
}
.nav-tabs > li:nth-child(2) > a{
    border-radius: 0 !important;
}
.nav-tabs > li:last-child > a{
    border-radius: 0 4px 4px 0 !important;
    border-left: 0;
}
.nav-tabs > li > a:hover {
    background-color: #58585a;
    border: 1px solid #58585a;
    color: #fff;
}
.nav-tabs > li.active > a {
    background-color: $yellow !important;
    border: 1px solid $yellow !important;
}
.pre-tabs {
    margin-top: 100px;
}
.tabs_content_pre_risorsa a:hover {
  text-decoration: none;
}
.tabs_content_pre_risorsa{
    margin-top: 50px;
}
.tabs_content_pre_risorsa h2 {
    font-size: 20px;
    color: #58585a;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px;
    letter-spacing: 3px;
}
.tabs_content_pre_risorsa p {
    font-size: 15px;
    color: #808080;
    line-height: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    padding: 0 100px;
    margin: 40px 0;
}
/*PRE-RISORSA_SINGLE RES*/
.resources {
    margin-top: 100px;
    // div#rooms {
    //   padding-left: 10px;
    //   padding-right: 0px;
    // }
    // div#art_cities {
    //   padding-left: 10px;
    //   padding-right: 0px;
    // }
}
.resources.second_row{
    margin-top: 100px;
}
.resources h3{
    font-size: 20px;
    color: #58585a;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px;
    letter-spacing: 3px;
    padding: 10px 0;
}
.resources.second_row h3{
    margin-bottom: 40px;
}
.resources .mTop {
  margin-top: 30px;
}
.resources h4.apartaments_box_titles {
    width: 220px;
    padding: 10px 0;
    background-color: #fff;
    border: 1px solid #dedfdf;
    margin: 0 auto;
    font-size: 15px;
    color: #58585a;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-top: 30px;
    letter-spacing: 3px;
    z-index: 20;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}
.resources .photo_box {
    height: 100%;
    background-color: #000;
    margin-top: -20px;
    z-index: 10;
    position: relative;
    border: 1px solid #dedfdf;
}
.resources .photo_box img{
    opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.resources a:hover .photo_box img{
    opacity: 0.2;
}
.resources a.link-resource{
    display: block;
}
.resources a.link-resource:hover .resource_overlay{
    display: block;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1,1);
}
.resources .resource_overlay {
    position: absolute;
    left: 50%;
    top: 60%;
    width: 100%;
    transform: translate(-50%, -50%) scale(1.2,1.2);
    z-index: 30;
    text-decoration: none;
    color: #fff;
    text-align: center;
    opacity: 0;
    transition:all .5s;
    &.weddings-overlay{
        top: 65%;
    }
}
.resources .resource_overlay.second_overlay{
    top: 45%;
}
.resources .resource_overlay p.from{
    margin-bottom: 0;
}
.resources .resource_overlay p.price{
    font-size: 20px;
    color: $yellow;
    margin-bottom: 40px
}
.resources .resource_overlay span.discover_button {
    border-radius: 4px;
    border: 2px solid $yellow;
    padding: 10px;
}
.resources .resource_overlay ul.acf_single_resource {
    list-style-type: none;
    padding: 0;
    margin-top: 40px;
}
.resources .resource_overlay ul.acf_single_resource li {
    display:inline-block;
    margin: 0 5px;
}
.resources .resource_overlay ul.acf_single_resource li i {
    font-size: 30px;
}
.resources .resource_overlay ul.acf_single_resource li span.acf_single_resource_text{
    color: $yellow;
    font-size: 12px;
}
#rooms h4.second_resource_title,
#art_cities h4.second_resource_title{
    font-size: 15px;
    margin-top: 20px;
}
#rooms .photo_box.second_level,
#art_cities .photo_box.second_level{
    margin-top: 0;
}
/*.fancy-text-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: table;
}
.fancy-text{
    vertical-align: middle;
    display: table-cell;
}*/
.fancy-row {
    //margin: 100px 0;
    margin-top: 100px;
    margin-bottom: 100px;
    // div {
    //   padding-left: 0px;
    //   padding-right: 0px;
    // }
    // .fancy-text-container {
    //   padding-left: 15px;
    //   padding-right: 15px;
    // }
    // .fancy-text {
    //   margin-left: 15px;
    // }
}
.fancy-text-container {
    margin-top: 40px;
}
.fancy-text h4{
    color: #58585a;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 3px;
}
.fancy-text p{
    margin-top: 30px;
    //padding-right: 170px;
    text-align: justify;
}
.fancy-row a:first-child img {
    margin-bottom: 30px;
}
.fancy-row a:last-child img {
    margin-top: 0;
}
a.fancy_image img {
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    width: 100%;
    height: auto;
}
a.fancy_image img:hover {
    opacity: 0.5;
}
.fancybox-skin {
    padding: 0 !important;
}
.fancybox-close {
    top: 0px !important;
    right: -400px !important;
}
/*POST CSS*/
img.slider_arrow {
    max-width: 32px;
    margin-left: -10px;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}
h1.entry-title {
    color: #3e3d40;
    font-weight: 700;
    font-size: 65px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 50px;
}
h2.entry-title {
    color: #a4a5a6;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-decoration: none;
    padding: 15px;
    /*Truncate text*/
      display: block;
      /* Fallback for non-webkit */
      display: -webkit-box;
      max-width: 400px;
      height: 50px;
      /* Fallback for non-webkit */
      margin: 0 auto;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -moz-transition: 0.3s;
      -o-transition: 0.3s;
      -webkit-transition: 0.3s;
      transition: 0.3s;

}
h2.entry-title a:hover {
    color: $yellow;
}
.ctp_post_content {
    margin-bottom: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 2px;
    column-count: 2;
    line-height: 20px;
}
.ctp_post_content_paluffo {
    margin-bottom: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 2px;
    column-count: 1;
    line-height: 20px;
}
.separator_attributes_top{
    height:1px;
    background:#717171;
    border-bottom:1px solid #cbcccd;
    margin-bottom: 20px;
}
.separator_attributes_bottom{
    height:1px;
    background:#717171;
    border-bottom:1px solid #cbcccd;
    margin-top: 20px;
}
.single_attribute {
    text-align: center;
}
.single_attribute.check {
    text-align: left;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: #707173;
}
.single_attribute i {
    font-size: 30px;
    vertical-align: middle;
}
.single_attribute span.attribute_text{
    font-size: 17px;
    vertical-align: middle;
}
.single_attribute span.attribute_text span{
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    color: #707173;
}
.small_titles {
    color: #707173;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
}
.floor {
    margin: 20px 0;
}
.floor_list ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;
}
.floor_list ul li {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #8e9091;
    margin: 5px 0;
}
.lens-image i {
    font-size: 30px;
    background-color: rgba(40, 40, 40, 0.5);;
    position: absolute;
    color: #fff;
    bottom: 30px;
    padding: 10px;
}
/* centered columns styles */
.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:center;
    /* inline-block space fix */
    margin-right:-4px;
    font-size: 10px;

    img {
        border-radius:50%;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin: 0 auto;
    }
}
.col-centered img:hover {
    opacity: 0.8;
}
.centered-and-cropped {
    object-fit: cover;
}
.category_list_title h3 {
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #58585a;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 80px;
}
.single_calendar {
  margin-bottom: 20px;
  padding-left: 0;

  .single_dates {
      color: #a3a5a6;
      margin-bottom: 20px;

  }
  .single_price_week{
    color: #707173;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 18px;

  }
  .single_price_night{
    color: #a3a5a6;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
  }
}

// SHOP STYLES
ul{
    &.products {
        list-style: none;
    }
    li {
      a {
        color: #58585a;
        -webkit-transition: all .2s linear 0s;
        -moz-transition: all .2s linear 0s;
        -o-transition: all .2s linear 0s;
        -webkit-transition-property: all .2s linear 0s;
        transition-property: all .2s linear 0s;
        span {
          &.price {
            color: #58585a;
          }
        }
        &:hover{
          text-decoration: none;
          color: #e3ad49;
        }
      }
    }
}

a#images {
    background: transparent;
    text-decoration: none;
    color: $yellow;
    border: 1px solid $yellow;
}
.activeI {
    background: transparent !important;
    text-decoration: none !important;
    color: $yellow !important;
    border: 1px solid $yellow !important;
}
.noactiveI {
  background: $yellow !important;
  border: 1px solid $yellow !important;
  color: #ffffff !important;
}

div#spazio {
  height: 10px;
}

.default_P {
  margin-top: 30px;
  margin-bottom: 30px;
  h1,h2,h3,h4,h5,h6 {
    color: $yellow;
    font-style: italic;
  }
}

.riassunto {
  span {
    font-style: italic;
    a {
      color: $yellow;
    }
  }
}

.titolo_news {
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: italic;
    color: $yellow;
    margin-top: 10px;
    margin-bottom: 10px;
    a {
      font-style: italic;
      color: $yellow;
    }
  }
}
.news_box {
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu-secondary-header-menu-container, .menu-secondary-header-menu-it-container {
  ul {
    list-style-type: none;
    li {
      a {
        color: $yellow !important;
      }
    }
  }
}

// .menu-secondary-header-menu-container, .menu-secondary-header-menu-it-container {
//   ul {
//     li {
//       float: left;
//       padding: 30px;
//     }
//     li:first-child {
//       padding-left: 0px;
//     }
//   }
// }
.footerMenu {
  ul {
    li {
      float: none;
      padding: 0px;
      a {
        color: #808080 !important;
      }
    }
  }
}
a.logoMobile {
  padding: 20px;
}
.accordionHoliday {
  margin-top: 50px;
}
.modal-body {
  img {
    border-radius: 0px !important;
    max-width: 100%;
    height: auto;
  }
}
a.mapFloor {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 15px;
  color: #8e9091;
  text-decoration: underline;
}
a.mapFloor:hover {
  color: #333333;
}
.mappa {
    iframe{
      width: 100%;
      display: block;
      pointer-events: none;
      position: relative; 
    }
    iframe.clicked{
      pointer-events: auto;
    }
}
.new_title {
  h1 {
    color: #3e3d40;
    font-weight: 700;
    font-size: 65px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 3px;
    margin-bottom: 0px !important;
  }
}
.containerBoxTitle {
   display: inline-block;
}
.risorseFour {
  .containerBoxTitle {
   display: inline-block;
  }
}