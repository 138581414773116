.widget {
    background-color: #e2ad49;
    height: 220px;
    position: relative;
}
*[uib-datepicker-popup-wrap].dropdown-menu {
      padding: 2px;
      *:focus {
        outline: none;
      }
      table {
        width: 100% !important;
        .btn {
          border: none;
        }
        .btn-info {
          background-color: #e2ad49;
        }
        .text-info {
          color: #c5a05a;
        }
        
      }
}
@media (max-width: 991px) {
  *[uib-datepicker-popup-wrap].dropdown-menu {
      position: absolute;
      width: inherit;
      border-radius: inherit;
      background-color: inherit;
      li {
        a {
          padding: inherit;
        }
      }
    }
}
@media (max-width: 375px) {
  .nav-tabs.widget-tabs.widget-tabs>li, .nav-pills>li {
    width: 100% !important;
    & > a {
      height: 30px;
      line-height: 30px;
      margin: 0 !important;
      padding: 0 !important;
      border: #fff 1px solid !important;
    }

    &:last-child > a{
      border-radius: 0 0 4px 4px !important;
    }
    &:first-child > a{
      border-radius: 4px 4px 0 0 !important;
    }
  }
  *[uib-datepicker-popup-wrap].dropdown-menu {
    background: white;
    position: absolute;
    left: 30px !important;
    right: 30px;
  }
}

form[name="bfwForm"] {
  width: 100%;
  background-color: #e2ad49;
}
widget {
  position: relative;
  display: block;
}
.widget-loading {
  width: 44px;
  height: 44px;
  background: url('../../images/loading.gif') center center no-repeat;
  background-size: contain;
  margin: -22px -22px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.select-child {
  line-height: 28px;
  position: relative;
    & > label {
      color: #333;
      font-weight: 500;
      margin-right: 8px;
    & > select {
      margin-left: 8px;
    }
  }
  .separator {
    width: 1px;
    height: 28px;
    background: #fff;
    display: inline-block;
    position: absolute;
  }

  .dropdown-menu {
    padding: 10px;
    top: 0 !important;
    width: auto;
  }
}

.post_widget .phrase,
.widget span.phrase {
  color: #fff;
  font-size: 18px;
  letter-spacing: 3px;
  font-family: 'Montserrat', sans-serif;
}
/*ul.resources-list{
  list-style: none;
  padding: 0;
}
ul.resources-list li{
  display: inline;
}
ul.resources-list li:first-child {
}
ul.resources-list li:nth-child(2) {
}
ul.resources-list li:last-child {
}*/
.first-widget-row {
  margin-top: 30px;
}
.second-widget-row{
  margin-top: 30px;
  margin-bottom: 30px;
}

.nav-tabs.widget-tabs.widget-tabs > li, .nav-pills > li {
    float:none;
    display:inline-block;
    width: 33%;
}
.nav-tabs.widget-tabs {
    text-align:center;
    border: 0;
}
.nav-tabs.widget-tabs > li > a{
    padding: 5px 20px !important;
    border: 1px solid #fff;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin: -2px !important;
}
.nav-tabs.widget-tabs > li:first-child > a{
    border-radius: 4px 0 0 4px !important;
    border-right: 0;
}
.nav-tabs.widget-tabs > li:nth-child(2) > a{
    border-radius: 0 !important;
}
.nav-tabs.widget-tabs > li:last-child > a{
    border-radius: 0 4px 4px 0 !important;
    border-left: 0;
}
.nav-tabs.widget-tabs > li > a:hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #58585a;
}
.nav-tabs.widget-tabs > li.active > a {
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: #58585a;
}
.widget-select {
  padding: 0;
}
.widget-select select {
  background-color: transparent;
  border: none;
  color: #333;
}
/*SELECT*/
.select-bg {
  background-color: #fff;
}
.select-bg:first-child{
  border-radius: 4px 0 0 4px;
  border-right: 0;
}
.select-bg:nth-child(2){
  border-left: 0 !important;
}
.select-bg:nth-child(2),
.select-bg:nth-child(3) {
  padding: 10.5px 10px;
  border: 1px solid #fff;
  border-right: 0;
  background-color: #fff !important;
  background-color: transparent;
  line-height: 35px;
}
.select-bg:last-child{
  padding: 0;
  line-height: 35px;
  border-radius: 0 4px 4px 0;
  border: 1px solid #fff;
  background-color: transparent;
  -o-transition: .3s ease-out;
	-ms-transition: .3s ease-out;
	-moz-transition: .3s ease-out;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;

  & > button[type="submit"] {
    padding: 11.5px 10px;
    width: 100%;
  }
}
.select-bg:last-child:hover{
  background-color: #fff;
}
.booking_btn button.book:hover{
  color: #58585a;
}
.booking_btn button.book{
  color: #fff;
  text-decoration: none;
  padding: 12px 50px;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: none;
  -webkit-appearance: none;
  border-style: none;
  padding: 0;
}
.calendar input{
  border: 0;

  &:focus {
    outline: 0px;
  }
}
.select-bg.calendar {
  height: 60px;
}
.selectholder {
  clear: both;
	width: 100%;
	font-size: 1.3em;
	height: 60px;
	color: #666;
	-o-transition: .3s ease-out;
	-ms-transition: .3s ease-out;
	-moz-transition: .3s ease-out;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
	cursor: pointer;

  
}

.selectholder .desc {
	display: inline-block;
	vertical-align: middle;
	padding-left: 15px;
	line-height: 2.4em;
  line-height: 60px;
}
.selectholder .pulldown {
	float: right;
	vertical-align: middle;
	background-size: cover;
	-o-transition: .3s ease-out;
	-ms-transition: .3s ease-out;
	-moz-transition: .3s ease-out;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
  margin-top: 14px;

  i {
    font-size: 15px;
    color: #8e9091;
    top: 4px;
  }
}

.selectholder:hover,
.activeselectholder {
	color: #e2ad49;
}

.selectholder:hover .pulldown,
.activeselectholder .pulldown {
	//background-image: url('../../images/pulldown.png');
  -webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	transform: rotate(90deg);

  i {
    color: #e2ad49;
  }
}

.selectholder .selectdropdown {
	position: relative;
	top: 0;
	left: 0;
	background: #fff;
	color: #707173;
	display: none;
	clear: both;
  margin: 0 -15px;
}

.selectholder .selectdropdown span {
	display: block;
	border-bottom: solid 1px #b8b9bb;
	padding-left: 30px;
	line-height: 2.4em;
	width: 100%;
	-o-transition: background-color .3s ease-out;
	-ms-transition: background-color .3s ease-out;
	-moz-transition: background-color .3s ease-out;
	-webkit-transition: background-color .3s ease-out;
	transition: background-color .3s ease-out;
}

.selectholder .selectdropdown span:last-child {
	border-bottom: none;
}

.selectholder .selectdropdown span.active {
	background-color: #7ee249;
  color: #fff;
	background-image: url('https://supereightstudio.com/img/radio_tick.png');
	background-repeat: no-repeat;
	background-size: 1.6em 1.6em;
	background-position: 98% 50%;
}

.selectholder .selectdropdown span:hover {
	background-color: #b8b9bb;
  color: #fff;
}

/*POST WIDGET*/
.post_widget {
    background-color: #e2ad49;
    padding: 20px 0;
    margin: 0 auto;
    border-radius: 5px 5px 0 0;
    max-width: 300px;
    height: 290px;
    position: relative;
    top: -80px;
}
.start_price {
  border-radius: 0 0 5px 5px;
  border: 1px solid #ccc;
  margin-top: 16px;
  border-top: 0;

}
.post_widget .widget-select span{
  color: #fff;
}
.post_widget .widget-select select {
  color: #58585a;
  box-shadow: none;
}
.post_widget .select-child {
  color: #fff;
}
.post_widget {
  .widget-select {
    span {
    color: #fff;
  }
    select {
    color: #58585a;
    }
  }
  .select-child {
  color: #fff;
  }
  .booking_btn_mobile {
    button {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      width: 100%;
      border: 0;
      letter-spacing: 5px;
      margin-top: 20px;
      border: 1px solid #fff;
      -o-transition: background-color .3s ease-out;
    	-ms-transition: background-color .3s ease-out;
    	-moz-transition: background-color .3s ease-out;
    	-webkit-transition: background-color .3s ease-out;
    	transition: background-color .3s ease-out;
      &:hover {
        color: #fff;
        background-color: transparent;
        border: 1px solid #fff;
        -o-transition: background-color .2s ease-out;
      	-ms-transition: background-color .2s ease-out;
      	-moz-transition: background-color .2s ease-out;
      	-webkit-transition: background-color .2s ease-out;
      	transition: background-color .2s ease-out;
      }
    }
  }
  .start_price {
    padding: 10px 30px;
    p {
      &.from {
        color: #e2ad49;
        margin-bottom: 0;
      }
    }
    p {
      &.price {
        color: #e2ad49;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 25px;
      }
    }
  }
}

@media(min-width: 991px) {
  .select-child {
    margin-right: 20px;
  }
  .post_widget .select-child {
    margin-right: 0px;
  }
  .first-widget-row .widget-title {
    margin-bottom: 30px;
  }
}

/* MOBILE */

@media(max-width: 991px) {
  .widget-mobile{
    background-color: #e2ad49;
    height: auto;
  }
  .select-child {
    margin-bottom: 20px;
    span {
      color: #58585a;
      text-align: left;
      font-size: 0.86em;
    }
    .form-control {
      option {
      }
    }
  }
  .widget-tabs{
    margin-top: 40px;
  }
  .widget-select{
    margin-top: 40px;
  }
  .widget-select select{
    background-color: #fff;
    width: 100%;
    border: 1px solid #cca872;
    color: #58585a;
  }
  .booking_btn_mobile button {
    margin-top: 30px;
    width: 100%;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2px;
    color: #58585a;
    padding: 10px 0;
  }
  .first-widget-row {
    padding: 0;
    text-align: center;
  }
  .widget-title {
    color: #FFF;
  }
  
}

@media (max-width: 480px) {
  .nav-tabs.widget-tabs > li > a{
    font-size: 12px;
    padding: 4px !important;
  }
}
