@media (max-width: 991px) {
	a.nav-expander {
		background: none repeat scroll 0 0 transparent;
		color: #FFFFFF;
		display: block;
		font-size: 15px;
		height: 50px;
		margin-right: 0;
		// padding: 1em 1.6em 2em;
		position: absolute;
		left: 0;
		text-decoration: none;
		text-transform: uppercase;
		top: 0;
		transition: left 0.3s ease-in-out 0s;
		width: 130px;
		z-index: 12;
		transition: left 0.3s ease-in-out 0s;
		-webkit-transition: left 0.3s ease-in-out 0s;
		-moz-transition: left 0.3s ease-in-out 0s;
		-o-transition: left 0.3s ease-in-out 0s;
	}
	a.nav-expander:hover {
		cursor: pointer;
	}
	a.nav-expander.fixed {
		position: fixed;
		i {
			&.icon-menu {
				font-size: 35px;
				color: $yellow;
			}
		}
	}
	.nav-expanded a.nav-expander.fixed {
		left: 20em;
	}
	nav {
		background: #2d2f33;
		display: block;
		height: 100%;
		overflow: auto;
		position: fixed;
		left: -20em;
		font-size: 15px;
		top: 0;
		width: 20em;
		z-index: 2000;
		transition: left 0.3s ease-in-out 0s;
		-webkit-transition: left 0.3s ease-in-out 0s;
		-moz-transition: left 0.3s ease-in-out 0s;
		-o-transition: left 0.3s ease-in-out 0s;
	}
	.nav-expanded nav {
		left: 0;
	}
	body.nav-expanded {
		// margin-left: 0em;
		transition: left 0.4s ease-in-out 0s;
		-webkit-transition: left 0.4s ease-in-out 0s;
		-moz-transition: left 0.4s ease-in-out 0s;
		-o-transition: left 0.4s ease-in-out 0s;
	}
	#nav-close {
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-weight: 300;
		font-size: 24px;
		padding-right: 10px;
	}
	.main-menu li {
		// margin-left: 20px;
		font-size: 18px;
		padding: 12px 0;
		&.dropdown {
			.dropdown-toggle {
				// i {
				//   float: right;
				// }
				.arrow {
					width: 13px;
					height: 13px;
					display: inline-block;
					position: relative;
					bottom: -5px;
					left: -10px;
					-webkit-transition: 0.4s ease;
					transition: 0.4s ease;
					margin-top: 2px;
					text-align: left;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					float: right;
					&:before, &:after {
						position: absolute;
						content: "";
						display: inline-block;
						width: 12px;
						height: 3px;
						background-color: #fff;
						-webkit-transition: 0.4s ease;
						transition: 0.4s ease;
					}
					&:after {
						position: absolute;
						-webkit-transform: rotate(90deg);
						transform: rotate(90deg);
						top: -5px;
						left: 5px;
					}
				}
			}
		}
		i {
			&.glyphicon-remove {
				font-size: 28px;
				&:before {}
			}
		}
	}
	.main-menu li a {
		color: #6F7D8C;
		text-decoration: none;
		padding-left: 10px;
	}
	.main-menu li a:hover {
		color: #FFFFFF;
		text-decoration: none;
	}
	.main-menu li .sub-nav {
		border-bottom: 0px;
		padding: 4px;
	}
	// .main-menu a .caret {
	// 	width: 0;
	// 	height: 0;
	// 	display: inline-block;
	// 	vertical-align: top;
	// 	border-top: 4px solid #4f5963;
	// 	border-right: 4px solid transparent;
	// 	border-left: 4px solid transparent;
	// 	content: "";
	//     margin-top: 8px;
	// }
	//
	// .main-menu a:hover .caret {
	// 	border-top-color: #4f5963;
	// }
	//
	// .main-menu li.open > a > .caret {
	// 	border-top: none;
	// 	border-bottom: 4px solid #4f5963;
	// 	border-right: 4px solid transparent;
	// 	border-left: 4px solid transparent;
	// }
	//
	// .main-menu li.open > a:hover > .caret {
	// 	border-bottom-color: #4f5963;
	// }
	.icon:before {
		font-family: 'FontAwesome';
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1;
		text-transform: none;
		content: '\f105';
	}
	.main-menu li> a> span.icon {
		float: right;
		margin: 0.1em 1.7em -0.1em 0;
		opacity: 0;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
	.main-menu li> a:hover> span.icon {
		float: right;
		margin: 0.1em 0.8em -0.1em 0;
		opacity: 1;
	}
	.dropdown-menu {
		position: relative;
		width: 100%;
		border-radius: 0px;
		background-color: #181b1f;
		li {
			a {
				padding: 10px 10px;
			}
		}
	}
	// .navmenu-mobile {
	//   float: left;
	// }
	// .navbar-toggle {
	//   font-size: 25px;
	//   color: #fff;
	//   position: absolute;
	//   z-index: 999;
	// }
	// .navbar-mobile {
	//   height: 80px;
	//   -webkit-transition: all 0.5s ease;
	//   -moz-transition: all 0.5s ease;
	//   -o-transition: all 0.5s ease;
	//   transition: all 0.5s ease;
	//   .navbar-toggle{
	//       display: block !important;
	//       float: left;
	//       i {
	//         &.icon-menu {
	//           font-size: 35px;
	//         }
	//       }
	//     }
	//   }
	//
	// ul.yellow_voices {
	//   list-style: none;
	//   padding: 10px 15px;
	//   li.single_yellow_voice {
	//     margin: 20px 0;
	//     a{
	//       text-decoration: none;
	//       color: #e2ad49;
	//       font-size: 18px;
	//     }
	//   }
	// }
	// .mobile-list {
	//   margin-bottom: 15px;
	//   li{
	//     a {
	//       font-size: 18px;
	//     }
	//   }
	// }
	// .dropdown-menu>li>a {
	//   padding: 8px 15px;
	// }
	// .navbar-brand-mobile{
	//   position: absolute;
	//   width: 100%;
	//   left: 0;
	//   top: 10px;
	//   text-align: center;
	//   margin: auto;
	//   padding:0;
	// }

	// NEW WPML
	.wpml-ls-statics-shortcode_actions {
		background-color: transparent;
		border: none;
		ul {
			li {
				&.wpml-ls-item {
					border: 2px solid #fff;
					border-radius: 100px !important;
					margin-right: 10px;
						&:last-child {
							margin-right: 0;
						}
					a {
						// width: 50px;
						// height: 30px;
						// line-height: 25px;
						width: 35px;
						height: 35px;
						background: transparent;
						padding: 0 !important;
						// border-radius: 5px;
						text-align: center;
						display: inline-block;
						span {
							font-size: 12px;
						}
					}
				}
				&:hover {
						border: 2px solid #e3ad49;
						background-color: #e3ad49;
				}
			}
		}
	}
}

@media (max-width: 1999px) and (min-width: 768px) {
	.navbar-header {
		float: none;
	}
}
